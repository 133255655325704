.inital-page {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.inital-page .div-5 {
    background-color: #ffffff;
    overflow: hidden;
}

.inital-page .overlap-3 {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    /* Ensure the parent is aligned to the left */
}

.inital-page .rectangle-6 {
    position: absolute;
    top: 0;
    left: 0;
    /* Align to the left for mobile responsiveness */
    width: 100vw;
    /* Make it take the full viewport width */
    height: 100vh;
    /* Make it take the full viewport height */
    background-color: #02111C;
    /* Add a background color if needed */
    /* z-index: -1; */
    /* Ensure it is behind other elements if needed */
}

.inital-page .frame-28 {
    align-items: center;
    /* Center content horizontally */
    display: flex;
    /* Use flexbox */
    flex-direction: column;
    /* Align children vertically */
    gap: 7px;
    width: 344px;
    /* Adjust width to leave margins on sides */
    transform: translateX(0);
    /* Reset transform to avoid misalignment */
    position: relative;
    /* Change to relative to avoid conflicts */
}

.inital-page .group-19 {
    height: 27px;
    /* width: 80px; */
    display: flex;
    /* Ensure proper alignment */
    /* justify-content: center; */
    /* Center content horizontally */
    /* align-items: center; */
    /* Center content vertically */
}

.inital-page .frame-29 {
    align-items: center;
    background-color: var(--text-150);
    border-radius: 4px;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 8px 10px;
    position: relative;
}

.inital-page .text-wrapper-24 {
    color: var(--primary-500main);
    font-family: "Aeonik TRIAL-Bold", Helvetica;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.52px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.inital-page .frame-30 {
    align-items: self-start;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    max-width: 344px;
}

.inital-page .group-wrapper{
    position: relative;
    white-space: nowrap;
    width: 100%;
    max-width: 344px;
}



.inital-page .claim-your-GOO-4 {
    color: transparent;
    font-family: "Aeonik TRIAL-Bold", Helvetica;
    font-size: 28px;
    font-weight: 700;
    max-width: 344px;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -0.52px;
    position: relative;
    width: 268px;
}

.inital-page .text-wrapper-25 {
    color: #ffffff;
}

.inital-page .text-wrapper-26 {
    color: #ffba15;
}

.inital-page .text-wrapper-27 {
    color: var(--text-600);
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.inital-page .element-3 {
    height: 427px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 404px;
    width: 668px;
}

.inital-page .mobile-iframe-container {
    height: 40%;
    /* Set the height of the iframe */
    width: 100%;
    /* Use the full width of the viewport */
    position: absolute;
    /* Position it absolutely */
    bottom: -5%;
    /* Adjust the top position as needed */
    left: 0;
    /* Align to the left */
    object-fit: cover;
    /* z-index: -1; */
    /* Ensure the iframe content covers the container */
}

.inital-page .mobile-iframe-container iframe {
    width: 100%;
    /* Make the iframe take the full width of its container */
    height: 100%;
        /* 确保iframe的高度减去padding-bottom的高度 */    /* Make the iframe take the full height of its container */
    border: none;
    margin-top: 10px;
}

.inital-page .frame-31 {
    align-items: center;
    /* Center content horizontally */
    display: flex;
    /* Use flexbox */
    flex-direction: column;
    /* Align children vertically */
    gap: 13px;
    width: 100%;
    /* Adjust width to leave margins on sides */
    transform: translateX(0);
    /* Reset transform to avoid misalignment */
    position: relative;
    /* Change to relative to avoid conflicts */
}

.inital-page .main-component-container {
    height: 60%;
    display: flex;
    /* Use flexbox for alignment */
    flex-direction: column;
    /* Align children vertically */
    position: absolute;
    /* top: 10%; */
    margin-top: 5%;
    width: 100%;
    justify-content: center;
    /* Center content vertically within the container */
    align-items: center;
    /* Center content horizontally within the container */
    gap: 20px;
    /* Adjust gap between frame-28 and frame-31 as needed */
    /* z-index: 1; */
}


.inital-page .frame-32 {
    align-items: center;
    /* Center content horizontally */
    display: flex;
    /* Use flexbox */
    flex-direction: column;
    /* Align children vertically */
    gap: 6px;
    width: 100%;
    /* max-width: 334px; */
    /* Ensure it takes full width of the parent */
}

.inital-page .text-wrapper-28 {
    color: var(--text-300);
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.28px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    max-width: 344px;
}

.inital-page .wallet-address-wrapper {
    color: var(--primary-500main);
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.28px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: 100%;
    max-width: 344px;
}

@media (max-width: 786px) {
    .inital-page .wallet-address-wrapper {
        font-size: 12px;
    }
}

.inital-page .frame-33 {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--primary-500main);
    border-radius: 10px;
    display: flex;
    flex: 0 0 auto;
    gap: 6px;
    padding: 9px 14px;
    position: relative;
    width: 100%;
    max-width: 344px;
}

.inital-page .group-20 {
    height: 30px;
    position: relative;
    width: 30px;
}

.inital-page .frame-34 {
    background-color: #ffffff;
    border: 0.71px solid;
    border-color: var(--text-300);
    border-radius: 14.89px;
    height: 30px;
}

.inital-page .group-21 {
    background-image: url(https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c4d04b4422ee4cb52442/img/vector-3.svg);
    background-size: 100% 100%;
    height: 17px;
    left: 6px;
    position: relative;
    top: 6px;
    width: 17px;
}

.inital-page .group-22 {
    background-color: #ffffff;
    border-radius: 2.18px;
    height: 4px;
    left: 5px;
    position: absolute;
    top: 6px;
    width: 4px;
}

.inital-page .ellipse-11 {
    background-color: #000000;
    border-radius: 1.59px;
    height: 3px;
    left: 1px;
    position: relative;
    top: 1px;
    width: 3px;
}

.inital-page .group-23 {
    background-color: #ffffff;
    border-radius: 2.18px;
    height: 4px;
    left: 10px;
    position: absolute;
    top: 2px;
    width: 4px;
}

.inital-page .frame-35 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 87px;
    position: relative;
    width: 90%;
}

.inital-page .text-wrapper-29 {
    color: var(--text-600);
    font-family: "Aeonik TRIAL-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.inital-page .connect-wallet-to-wrapper {
    align-items: center;
    background-color: var(--primary-500main);
    border-radius: 10px;
    display: flex;
    gap: 10px;
    height: 44px;
    justify-content: center;
    padding: 14px 62px;
    flex-direction: column;
    width: 100%;
    max-width: 344px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
}

.inital-page .connect-wallet-to-wrapper.disabled {
    background-color: #ccc;
    /* Disabled background color */
    cursor: not-allowed;
    /* Change cursor to not-allowed to indicate it's disabled */
}

.inital-page .connect-wallet-to {
    color: var(--white);
    font-family: var(--notes-bold-font-family);
    font-size: var(--notes-bold-font-size);
    font-style: var(--notes-bold-font-style);
    font-weight: var(--notes-bold-font-weight);
    letter-spacing: var(--notes-bold-letter-spacing);
    line-height: var(--notes-bold-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.inital-page .button-instance {
    left: 267px !important;
    position: fixed !important;
    top: 66px !important;
}



.inital-page .frame-36 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

.inital-page .status-bar-2 {
    width: 375px !important;
}

.inital-page .logo-wrapper {
    height: 54px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 10px;
    margin-right: 10px;
}

.inital-page .logo-3 {
    align-items: center;
    display: inline-flex;
    gap: 5.44px;
    left: 15px;
    position: relative;
}

.inital-page .group-24 {
    height: 33.98px;
    position: relative;
    width: 33.98px;
}

.inital-page .frame-37 {
    background-color: #ffffff;
    border-radius: 16.86px;
    height: 34px;
    width: 34px;
}

.inital-page .group-25 {
    background-image: url(https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c4d04b4422ee4cb52442/img/vector-2.svg);
    background-size: 100% 100%;
    height: 20px;
    left: 7px;
    position: relative;
    top: 7px;
    width: 20px;
}

.inital-page .group-26 {
    background-color: #ffffff;
    border-radius: 2.47px;
    height: 5px;
    left: 6px;
    position: absolute;
    top: 7px;
    width: 5px;
}

.inital-page .ellipse-12 {
    background-color: #000000;
    border-radius: 1.81px;
    height: 4px;
    left: 1px;
    position: relative;
    top: 1px;
    width: 4px;
}

.inital-page .group-27 {
    background-color: #ffffff;
    border-radius: 2.47px;
    height: 5px;
    left: 12px;
    position: absolute;
    top: 2px;
    width: 5px;
}

.inital-page .group-28 {
    height: 22.34px;
    position: relative;
    width: 72.59px;
}

.inital-page .group-29 {
    height: 18px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 12px;
}

.inital-page .group-30 {
    border: 2.49px solid;
    border-color: #ffffff;
    border-radius: 6px;
    height: 12px;
    left: 13px;
    position: absolute;
    top: 4px;
    width: 12px;
}

.inital-page .ellipse-13 {
    background-color: #ffffff;
    border-radius: 2.35px;
    height: 5px;
    left: 2px;
    position: relative;
    top: 3px;
    width: 5px;
}

.inital-page .group-31 {
    border: 2.49px solid;
    border-color: #ffffff;
    border-radius: 6px;
    height: 12px;
    left: 26px;
    position: absolute;
    top: 4px;
    width: 12px;
}

.inital-page .ellipse-14 {
    background-color: #ffffff;
    border-radius: 2.35px;
    height: 5px;
    left: 4px;
    position: relative;
    top: 3px;
    width: 5px;
}

.inital-page .group-32 {
    height: 16px;
    left: 39px;
    position: absolute;
    top: 0;
    width: 12px;
}

.inital-page .overlap-group-4 {
    height: 16px;
    position: relative;
}

.inital-page .ellipse-15 {
    border: 2.49px solid;
    border-color: #ffffff;
    border-radius: 6px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 4px;
    width: 12px;
}

.inital-page .rectangle-7 {
    background-color: #ffffff;
    border-radius: 0px 0px 1.24px 1.24px;
    height: 12px;
    left: 0;
    position: absolute;
    top: 0;
    width: 2px;
}

.inital-page .subtract-3 {
    height: 12px;
    left: 52px;
    position: absolute;
    top: 4px;
    width: 12px;
}

.inital-page .frame-38 {
    align-items: flex-start;
    display: flex;
    height: 12px;
    left: 65px;
    position: absolute;
    top: 4px;
    width: 7px;
}

.inital-page .rectangle-8 {
    background-color: #ffffff;
    height: 11.99px;
    position: relative;
    transform: rotate(180deg);
    width: 2.47px;
}

.inital-page .ellipse-16 {
    background-color: #ffffff;
    border-radius: 2.35px;
    height: 4.7px;
    position: relative;
    width: 4.7px;
}

.inital-page .overlap-4 {
    height: 100%;
    /* position: relative; */
    width: 100%;
    display: flex;
}

.inital-page .element-4 {
    height: 1084px;
    left: 406px;
    position: absolute;
    top: 0;
    width: 1698px;
}

.inital-page .wallet-3 {
    right: 5% ;
    position: absolute ;
    top: 5% ;
    /* z-index: 1002; Ensure it is above other elements */
}


.inital-page .frame-39 {
    background-color: #02111c;
    height: 100%;
    width: 35.6%;
    /* Fixed width for the authentication area */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center content horizontally */
    justify-content: center;
    /* Center content vertically */
}

/* .inital-page .frame-40 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 3vh;
    justify-content: center;
    position: relative;
    max-width: 100%;
    width: 100%;
    top: -5rem;
} */

.inital-page .frame-40 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 3vh;
    /* Relative gap */
    justify-content: center;
    position: relative;
    width: 90%;
    top: -5rem;
    /* Set to 200px for example */
    max-width: 100%;
    /* Ensure it doesn't exceed the parent's width */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
    overflow: visible;
    margin-left: 6vw;
    margin-right: 6vw;
    height: auto;

    /* Hide overflow if necessary */
}

/* 
.inital-page .frame-41 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 2.8vh;
    margin-right: -1.09375%;
    position: relative;
}

.inital-page .frame-44 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 14px;
    position: relative;
}

.inital-page .frame-52 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 546px;
} */

.inital-page .frame-41,
.inital-page .frame-44,
.inital-page .frame-52 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2.5vh;
    /* Adjust as needed */
    width: 100%;
    /* Ensure they take the full width of the parent */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
}

.inital-page .frame-41 {
    flex: 1 0 auto;
    /* Allow to grow and shrink */
    gap: 2.8vh;
    /* Relative gap */
    position: relative;
}

.inital-page .frame-44 {
    flex: 1 0 auto;
    /* Allow to grow and shrink */
    gap: 1.3vh;
    /* Relative gap */
    position: relative;
}

.inital-page .frame-52 {
    flex: 1 0 auto;
    /* Allow to grow and shrink */
    gap: 0.93vh;
    /* Relative gap */
    position: relative;
    width: 100%;
    /* Ensure it takes the full width of the parent */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
    /* top: 1.5rem; */
}

.inital-page .logo-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 9.22px;
    position: relative;
}

.inital-page .group-33 {
    height: 57.64px;
    position: relative;
    width: 57.64px;
}

.inital-page .frame-42 {
    background-color: #ffffff;
    border-radius: 28.61px;
    height: 57px;
    width: 57px;
}

.inital-page .group-34 {
    background-image: url(https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c67f2404a95919d14329/img/vector-13.svg);
    background-size: 100% 100%;
    height: 34px;
    left: 12px;
    position: relative;
    top: 12px;
    width: 33px;
}

.inital-page .group-35 {
    background-color: #ffffff;
    border-radius: 4.18px;
    height: 8px;
    left: 10px;
    position: absolute;
    top: 12px;
    width: 8px;
}

.inital-page .ellipse-17 {
    background-color: #000000;
    border-radius: 3.06px;
    height: 6px;
    left: 1px;
    position: relative;
    top: 1px;
    width: 6px;
}

.inital-page .group-36 {
    background-color: #ffffff;
    border-radius: 4.18px;
    height: 8px;
    left: 20px;
    position: absolute;
    top: 4px;
    width: 8px;
}

.inital-page .group-37 {
    height: 37.91px;
    position: relative;
    width: 123.14px;
}

.inital-page .group-38 {
    height: 31px;
    left: 0;
    position: absolute;
    top: 7px;
    width: 20px;
}

.inital-page .group-39 {
    border: 4.22px solid;
    border-color: #ffffff;
    border-radius: 10.17px;
    height: 20px;
    left: 22px;
    position: absolute;
    top: 7px;
    width: 20px;
}

.inital-page .ellipse-18 {
    background-color: #ffffff;
    border-radius: 3.99px;
    height: 8px;
    left: 4px;
    position: relative;
    top: 6px;
    width: 8px;
}

.inital-page .group-40 {
    border: 4.22px solid;
    border-color: #ffffff;
    border-radius: 10.17px;
    height: 20px;
    left: 44px;
    position: absolute;
    top: 7px;
    width: 20px;
}

.inital-page .ellipse-19 {
    background-color: #ffffff;
    border-radius: 3.99px;
    height: 8px;
    left: 6px;
    position: relative;
    top: 6px;
    width: 8px;
}

.inital-page .group-41 {
    height: 27px;
    left: 67px;
    position: absolute;
    top: 0;
    width: 20px;
}

.inital-page .overlap-group-5 {
    height: 27px;
    position: relative;
}

.inital-page .ellipse-20 {
    border: 4.22px solid;
    border-color: #ffffff;
    border-radius: 10.17px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 7px;
    width: 20px;
}

.inital-page .rectangle-9 {
    background-color: #ffffff;
    border-radius: 0px 0px 2.1px 2.1px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 4px;
}

.inital-page .subtract-4 {
    height: 20px;
    left: 89px;
    position: absolute;
    top: 7px;
    width: 20px;
}

.inital-page .frame-43 {
    align-items: flex-start;
    display: flex;
    height: 20px;
    left: 111px;
    position: absolute;
    top: 7px;
    width: 12px;
}

.inital-page .rectangle-10 {
    background-color: #ffffff;
    height: 20.34px;
    position: relative;
    transform: rotate(180deg);
    width: 4.19px;
}

.inital-page .ellipse-21 {
    background-color: #ffffff;
    border-radius: 3.99px;
    height: 7.97px;
    position: relative;
    width: 7.97px;
}



.inital-page .group-42 {
    height: 50px;
    position: relative;
    width: 128px;
}

.inital-page .frame-45 {
    align-items: center;
    background-color: #ffffff;
    border-radius: 10px;
    display: inline-flex;
    gap: 10px;
    justify-content: center;
    padding: 16px 14px;
    position: relative;
}

.inital-page .text-wrapper-30 {
    color: var(--primary-500main);
    font-family: var(--content-bold-font-family);
    font-size: 1vw;
    font-style: var(--content-bold-font-style);
    font-weight: var(--content-bold-font-weight);
    letter-spacing: var(--content-bold-letter-spacing);
    line-height: var(--content-bold-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.inital-page .frame-46 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: auto;
}

.inital-page .text-wrapper-31 {
    color: var(--text-600);
    font-family: Arial, sans-serif;
    font-size: 1.6vw;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.inital-page .frame-47 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.inital-page .text-wrapper-32 {
    color: var(--text-300);
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

@media (min-width: 200px) {
    .inital-page .text-wrapper-32 {
        font-size: 30px;
    }
}

.inital-page .frame-48 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    position: relative;
    width: 100%;
}

.inital-page .frame-49 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--primary-500main);
    border-radius: 10px;
    height: 66px;
    position: relative;
    width: 100%;
}

.inital-page .frame-50 {
    align-items: center;
    display: inline-flex;
    gap: 10px;
    left: 20px;
    position: relative;
    top: 12px;
    width: 90%
}

.inital-page .group-43 {
    height: 42px;
    position: relative;
    width: 42px;
}

.inital-page .frame-51 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: var(--text-300);
  border-radius: 20.84px;
  height: 42px;
}

.inital-page .group-44 {
    background-image: url(https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c67f2404a95919d14329/img/vector-14.svg);
    background-size: 100% 100%;
    height: 24px;
    /* left: 8px; */
    position: relative;
    top: 8px;
    width: 24px;
}

.inital-page .group-45 {
    background-color: #ffffff;
    border-radius: 3.05px;
    height: 6px;
    left: 7px;
    position: absolute;
    top: 9px;
    width: 6px;
}

.inital-page .ellipse-22 {
    background-color: #000000;
    border-radius: 2.23px;
    height: 4px;
    left: 1px;
    position: relative;
    top: 1px;
    width: 4px;
}

.inital-page .group-46 {
    background-color: #ffffff;
    border-radius: 3.05px;
    height: 6px;
    left: 15px;
    position: absolute;
    top: 3px;
    width: 6px;
}

.inital-page .enter-your-name-here {
    background: #ffffff;
    /* Set a background color */
    border: none;
    /* Add black underline */
    border-radius: 0;
    /* Remove border radius for the underline */
    color: black;
    display: block;
    /* Use block instead of inline-flex */
    font-family: Helvetica;
    font-size: 1vw;
    /* Use vw for responsive font size */
    font-weight: 400;
    letter-spacing: 0.32px;
    line-height: normal;
    padding: 10px;
    /* Add padding for better spacing inside the input */
    position: relative;
    white-space: nowrap;
    width: 95%;
    /* Ensure it takes the full width of its parent */
    box-sizing: border-box;
    /* Include padding and border in the element's total width and height */
    height: 40px;
    /* Set a fixed height */
    caret-color: #FFBA15;
}

.inital-page .enter-your-name-here:focus {
    outline: none;
    /* Remove default outline */
    border-bottom: 2px solid #FFBA15;
    /* Change the underline on focus */
}

.inital-page .enter-your-name-here::placeholder {
    color: gray;
}


@media (max-width: 786px) {
    .inital-page .enter-your-name-here {
        font-size: 14px;
        width: 94%;
        padding: 8px;
        height: 35px;
    }
}


.inital-page .frame-53 {
    background-color: var(--primary-500main);
    border: 1px solid var(--primary-500main);
    border-radius: 10px;
    height: 66px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.inital-page .frame-53:hover {
    background-color: #FF9900;
}

.inital-page .frame-53 {
    background-color: var(--primary-500main);
    border: 1px solid var(--primary-500main);
    border-radius: 10px;
    height: 66px;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.inital-page .frame-53.disabled {
    background-color: #ccc;
    /* Disabled background color */
    border-color: #ccc;
    /* Disabled border color */
    cursor: not-allowed;
    /* Change cursor to not-allowed to indicate it's disabled */
}

@media (max-width: 786px) {
    .inital-page .enter-your-name-here {
        font-size: 14px;
        width: 94%;
        padding: 8px;
        height: 35px;
    }
}

.inital-page .text-wrapper-33 {
    color: #ffffff;
    font-family: "Aeonik TRIAL-Bold", Helvetica;
    font-size: 1.2vw;
    font-weight: 700;
    letter-spacing: 0.32px;
    line-height: normal;
    white-space: nowrap;
    position: relative;
}

.inital-page .iframe-container {
    flex: 1;
    /* Take the remaining space */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 103vh;
    z-index: 1000;
}

.inital-page .iframe-container iframe {
    width: 100%;
    height: 100%;
    border: none;
}

.inital-page .frame-39,
.inital-page .iframe-container {
    transition: background-color 0.3s ease;
}

.inital-page .connect-button {
    position: fixed;
    right: 5%;
    top: 4%;
    z-index: 1005;
    max-height: 55px;
    padding: 10px;
    z-index: 1005;
}

@media (max-width: 786px) {
    .inital-page .connect-button {
        position: relative;
        padding :0%;
        /* Ensures the button remains fixed on mobile */
        /* right: 10px; */
        /* Adjust the right margin for better visibility */
        top: 0px;
        /* Adjust the top margin for better visibility */
        z-index: 1005;
        /* padding: 10px; */
        
    }
}



.inital-page .frame-168 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 100%;
    max-width: 344px;
    /* left: 172px; */
    position: relative;
    /* top: 266px; */
}

.inital-page .frame-169 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
}

.inital-page .to-fully-secure-your {
    color: var(--text-300);
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.28px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 344px;
}

.inital-page .text-wrapper-93 {
    letter-spacing: 0.04px;
}

.inital-page .text-wrapper-94 {
    font-family: Helvetica;
    font-weight: 700;
    letter-spacing: 0.04px;
}

.inital-page .frame-170 {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--primary-500main);
    border-radius: 10px;
    display: flex;
    flex: 0 0 auto;
    gap: 6px;
    padding: 9px 14px;
    position: relative;
    width: 100%;
    height: auto;
}

.inital-page .frame-171 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 87px;
    margin-right: -3px;
    position: relative;
    width: 100%;
}

.inital-page .text-wrapper-95 {
    color: var(--text-900);
    font-family: Helvetica;
    font-size: 17px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    height: 100%;
    width: 100%;
    /* Full width within the wrapper */
    border: none;
    /* Removing any browser default borders */
    font-size: 16px;
    /* Increasing font size for better readability */
    outline: none;
    /* Removing focus outline */
    min-height: 5.3rem;
}

.inital-page .text-wrapper-96 {
    color: var(--primary-500main);
    font-family: "Aeonik TRIAL-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.inital-page .user-name-wrapper {
    color: #5A68FF;
    font-family: "Aeonik TRIAL-Regular", Helvetica;
    font-size: 20px;
    font-weight: 1000;
    font-style: bold;
    letter-spacing: 0.24px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.inital-page .web-user-name-wrapper {
    color: #5A68FF;
    font-family: "Aeonik TRIAL-Regular", Helvetica;
    font-size: 40px;
    font-weight: 1000;
    font-style: bold;
    letter-spacing: 0.24px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: 100%;
}


.inital-page .wallet-10 {
    align-items: center;
    background-color: #ffba15;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    height: 44px;
    justify-content: center;
    padding: 14px 62px;
    position: relative;
    width: 344px;
}

.inital-page .frame-172 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: center;
    margin-bottom: -2px;
    margin-top: -2px;
    position: relative;
}

.inital-page .property-1-twitter {
    border: 0.4px solid !important;
    border-radius: 10px !important;
    height: 20px !important;
    position: relative !important;
    width: 20px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inital-page .twitter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inital-page .x-2 {
    max-width: 100%;
    max-height: 100%;
}

/* .inital-page .property-twitter-instance {
    height: 8px !important;
    left: 3px !important;
    position: absolute !important;
    top: 3px !important;
    width: 8px !important;
} */
/* 
.inital-page .twitter.property-1-twitter-instance {
    display: flex;
    align-items: center;
    justify-content: center;
}

.inital-page .property-1-twitter-instance {
    border-radius: 166.67px !important;
    height: 13px !important;
    left: 3px !important;
    top: 3px !important;
    width: 13px !important;
}

.inital-page .x-2.property-twitter-instance {
    height: 8px !important;
    width: 8px !important;
    position: relative !important;
} */

.inital-page .text-wrapper-97 {
    color: var(--white);
    font-family: var(--notes-bold-font-family);
    font-size: var(--notes-bold-font-size);
    font-style: var(--notes-bold-font-style);
    font-weight: var(--notes-bold-font-weight);
    letter-spacing: var(--notes-bold-letter-spacing);
    line-height: var(--notes-bold-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.inital-page .frame-9 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 13px;
    /* left: 172px; */
    width: 100%;
    position: relative;
    max-width: 344px;
    /* top: 266px; */
}

.inital-page .frame-168 {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: 100%;
    max-width: 344px;
    /* left: 172px; */
    position: relative;
    /* top: 266px; */
}

.inital-page .frame-10 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 6px;
    position: relative;
}

.inital-page .text-wrapper-15 {
    color: var(--text-300);
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.28px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 344px;
}

.inital-page .wallet-2 {
    align-items: center;
    background-color: #ffba15;
    border-radius: 10px;
    display: flex;
    gap: 10px;
    height: 44px;
    justify-content: center;
    padding: 14px 62px;
    position: relative;
    width: 344px;
}

.inital-page .frame-11 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 6px;
    justify-content: center;
    margin-bottom: -2px;
    margin-top: -2px;
    position: relative;
}

.inital-page .discord-2 {
    height: 20px;
    position: relative;
    width: 20px;
}

.inital-page .text-wrapper-16 {
    color: var(--white);
    font-family: var(--notes-bold-font-family);
    font-size: var(--notes-bold-font-size);
    font-style: var(--notes-bold-font-style);
    font-weight: var(--notes-bold-font-weight);
    letter-spacing: var(--notes-bold-letter-spacing);
    line-height: var(--notes-bold-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.inital-page .text-wrapper-80 {
    color: #d70000;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    /* letter-spacing: 0.32px; */
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

@media (max-width: 786px) {
    .inital-page .text-wrapper-80 {
    font-size: 12px;
    width: 100%;
    max-width: 344px;

    }
}

.inital-page .modifed-container{
    width: 100%;
    gap:10px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;

}

.inital-page .frame-182 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 14px;
    position: relative;
    width: 100%;
    height: max-content;
}

.inital-page .frame-185 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.inital-page .frame-186 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
}

.inital-page .to-fully-secure-your-2 {
    color: var(--text-300);
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;
    line-height: normal;
    margin-top: -1px;
    position: relative;
    width: 100%;
}
@media (min-width: 2000px) {

    .inital-page .to-fully-secure-your-2,
    .inital-page .ive-just-claimed-my,
    .inital-page .text-wrapper-105,
    .inital-page .wallet-address-wrapper,
    .inital-page .text-wrapper-22,
    .inital-page .text-wrapper-23 {
        font-size: 25px !important;
    }
}

.inital-page .text-wrapper-103 {
    letter-spacing: 0.05px;
}

.inital-page .text-wrapper-104 {
    font-family: Helvetica;
    font-weight: 700;
    letter-spacing: 0.05px;
}

.inital-page .frame-187 {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
    height: 82px;
    position: relative;
    width: 98%;
}

.inital-page .frame-188 {
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--primary-500main);
    border-radius: 10px;
    height: 82px;
    position: relative;
    width: 100%;
}

.inital-page .ive-just-claimed-my-wrapper {
    align-items: flex-start;
    display: inline-flex;
    /* gap: 10px; */
    flex: 0 0 auto;
    /* left: 20px; */
    margin-right: -3px;
    position: relative;
    width: 100%;
    height: 98%;
    /* top: 12px; */
}

.inital-page .ive-just-claimed-my {
    align-items: flex-start;
    background: none;
    border: 0;
    color: var(--text-900);
    display: inline-flex;
    flex: 0 0 auto;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    gap: 187px;
    letter-spacing: 0.2px;
    line-height: normal;
    margin-top: -1px;
    padding: 0;
    position: flex;
    width: 100%;
    border: none;
    outline: none;
    height:100%;
    padding: 2%;
    min-height: 4.5rem;;
}

.inital-page .text-wrapper-79 {
    color: #ffba15;
    font-family: "Aeonik TRIAL-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

@media (min-width: 2000px) {
.inital-page .text-wrapper-79 {
        font-size: 25px;
    }
}

.inital-page .text-wrapper-105 {
    color: #ffba15;
    font-family: "Aeonik TRIAL-Regular", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: 100%;
}

.inital-page .frame-189 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
}

.inital-page .frame-190 {
    background-color: #ffba15;
    border: 1px solid;
    border-color: var(--text-900);
    border-radius: 10px;
    height: 66px;
    position: relative;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.inital-page .frame-190 :hover {
    background-color: #FF9900;
}

.inital-page .frame-191 {
    border-radius: 10px;
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
    /* left: 178px; */
    position: relative;
    /* top: 13px; */
    height: 100%;
}

.inital-page .icons-2 {
    border: 0.8px solid !important;
    border-radius: 20px !important;
    height: 40px !important;
    position: relative !important;
    width: 40px !important;
}

.inital-page .icons-3 {
    border-radius: 333.33px !important;
    height: 27px !important;
    left: 6px !important;
    top: 6px !important;
    width: 27px !important;
}

.inital-page .x-3 {
    height: 16px !important;
    left: 5px !important;
    position: absolute !important;
    top: 5px !important;
    width: 16px !important;
}

.inital-page .text-wrapper-106 {
    color: #ffffff;
    font-family: "Aeonik TRIAL-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.inital-page .frame-23 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 20px;
    position: relative;
    width: 100%;
}

.inital-page .frame-24 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 100%;
}

.inital-page .text-wrapper-22 {
    color: var(--text-300);
    font-family:  Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.32px;
    /* line-height: normal; */
    margin-top: -1px;
    position: relative;
    width: 100%;
}

.inital-page .frame-20 {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 14px;
    position: relative;
    width: 100%;
}

.inital-page .frame-25 {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 10px;
    position: relative;
    width: 98%;
    cursor: pointer;
}



.inital-page .frame-26 {
    background-color: #ffba15;
    border: 1px solid;
    border-color: var(--text-900);
    border-radius: 10px;
    height: 66px;
    position: relative;
    width: 100%;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.inital-page .frame-26:hover {
    background-color: #FF9900;
}

.inital-page .frame-27 {
    align-items: center;
    display: inline-flex;
    gap: 8px;
    justify-content: center;
    /* left: 182px; */
    position: flex;
    width:100%;
    height: 100%;
    /* top: 13px; */
}

.inital-page .discord-3 {
    height: 40px;
    position: relative;
    width: 40px;
}

.inital-page .text-wrapper-23 {
    color: #ffffff;
    font-family: "Aeonik TRIAL-Bold", Helvetica;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    line-height: normal;
    position: relative;
    white-space: nowrap;
    width: fit-content;
}

.custom-wallet-button-container {
    width: 100%;
    /* or any specific width you want */
    height: 66px;
    /* or any specific height you want */
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 786px) {
    .inital-page .enter-your-name-here {
        font-size: 16px;
        width: 94%;
        padding: 8px;
        height: 35px;
    }
}
@media (max-width :786px) {
    .inital-page .custom-wallet-button-container{
        width: 100%;
        max-width: 344px;
    }
    
}
/* Customize the WalletMultiButton */
.wallet-adapter-dropdown {
    width: 100%;
    height: 100%;
    font-size: 16px;

    /* Example: change background color */
    color: white;
    /* Example: change text color */
    border-radius: 10px;
    /* Example: change border radius */
}

.wallet-adapter-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* Ensure it takes the full height of its parent */
    width: 100%;
    /* Ensure it takes the full width of its parent */
    font-size: 16px;
    /* Adjust as needed */
    /* Example background color */
    color: white;
    /* Example text color */
    border-radius: 10px;
    /* Example border radius */
    box-sizing: border-box;
    min-width: 2vh;
    height: 100%;
    /* Ensure padding and border are included in the element's total width and height */
}