.claim-your-GOO {
    height: 136px;
    position: relative;
    width: 100%;
}

.claim-your-GOO .p {
    color: transparent;
    font-family: var(--h3-bold-font-family);
    font-size: var(--h3-bold-font-size);
    font-style: var(--h3-bold-font-style);
    font-weight: var(--h3-bold-font-weight);
    left: 0;
    letter-spacing: var(--h3-bold-letter-spacing);
    line-height: var(--h3-bold-line-height);
    position: absolute;
    top: -1px;
    width: 100%;
}

.claim-your-GOO .span {
    color: #ffffff;
    font-family: var(--h3-bold-font-family);
    font-size: var(--h3-bold-font-size);
    font-style: var(--h3-bold-font-style);
    font-weight: var(--h3-bold-font-weight);
    letter-spacing: var(--h3-bold-letter-spacing);
    line-height: var(--h3-bold-line-height);
}

.claim-your-GOO .text-wrapper-6 {
    color: #ffba15;
    font-family: var(--h3-bold-font-family);
    font-size: var(--h3-bold-font-size);
    font-style: var(--h3-bold-font-style);
    font-weight: var(--h3-bold-font-weight);
    letter-spacing: var(--h3-bold-letter-spacing);
    line-height: var(--h3-bold-line-height);
}