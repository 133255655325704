@import "https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap";
.claim-your-GOO {
  width: 100%;
  height: 136px;
  position: relative;
}

.claim-your-GOO .p {
  color: #0000;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
  width: 100%;
  position: absolute;
  top: -1px;
  left: 0;
}

.claim-your-GOO .span {
  color: #fff;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.claim-your-GOO .text-wrapper-6 {
  color: #ffba15;
  font-family: var(--h3-bold-font-family);
  font-size: var(--h3-bold-font-size);
  font-style: var(--h3-bold-font-style);
  font-weight: var(--h3-bold-font-weight);
  letter-spacing: var(--h3-bold-letter-spacing);
  line-height: var(--h3-bold-line-height);
}

.inital-page {
  background-color: #fff;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.inital-page .div-5 {
  background-color: #fff;
  overflow: hidden;
}

.inital-page .overlap-3 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.inital-page .rectangle-6 {
  background-color: #02111c;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.inital-page .frame-28 {
  flex-direction: column;
  align-items: center;
  gap: 7px;
  width: 344px;
  display: flex;
  position: relative;
  transform: translateX(0);
}

.inital-page .group-19 {
  height: 27px;
  display: flex;
}

.inital-page .frame-29 {
  background-color: var(--text-150);
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 10px;
  display: inline-flex;
  position: relative;
}

.inital-page .text-wrapper-24 {
  color: var(--primary-500main);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -.52px;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 10px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.inital-page .frame-30 {
  flex-direction: column;
  align-items: self-start;
  gap: 4px;
  width: 100%;
  max-width: 344px;
  display: flex;
}

.inital-page .group-wrapper {
  white-space: nowrap;
  width: 100%;
  max-width: 344px;
  position: relative;
}

.inital-page .claim-your-GOO-4 {
  color: #0000;
  letter-spacing: 0;
  width: 268px;
  max-width: 344px;
  margin-top: -.52px;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.inital-page .text-wrapper-25 {
  color: #fff;
}

.inital-page .text-wrapper-26 {
  color: #ffba15;
}

.inital-page .text-wrapper-27 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.inital-page .element-3 {
  object-fit: cover;
  width: 668px;
  height: 427px;
  position: absolute;
  top: 404px;
  left: 0;
}

.inital-page .mobile-iframe-container {
  object-fit: cover;
  width: 100%;
  height: 40%;
  position: absolute;
  bottom: -5%;
  left: 0;
}

.inital-page .mobile-iframe-container iframe {
  border: none;
  width: 100%;
  height: 100%;
  margin-top: 10px;
}

.inital-page .frame-31 {
  flex-direction: column;
  align-items: center;
  gap: 13px;
  width: 100%;
  display: flex;
  position: relative;
  transform: translateX(0);
}

.inital-page .main-component-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 60%;
  margin-top: 5%;
  display: flex;
  position: absolute;
}

.inital-page .frame-32 {
  flex-direction: column;
  align-items: center;
  gap: 6px;
  width: 100%;
  display: flex;
}

.inital-page .text-wrapper-28 {
  color: var(--text-300);
  letter-spacing: .28px;
  white-space: nowrap;
  width: 100%;
  max-width: 344px;
  margin-top: -1px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.inital-page .wallet-address-wrapper {
  color: var(--primary-500main);
  letter-spacing: .28px;
  white-space: nowrap;
  width: 100%;
  max-width: 344px;
  margin-top: -1px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

@media (width <= 786px) {
  .inital-page .wallet-address-wrapper {
    font-size: 12px;
  }
}

.inital-page .frame-33 {
  border: 1px solid;
  border-color: var(--primary-500main);
  background-color: #fff;
  border-radius: 10px;
  flex: none;
  align-items: center;
  gap: 6px;
  width: 100%;
  max-width: 344px;
  padding: 9px 14px;
  display: flex;
  position: relative;
}

.inital-page .group-20 {
  width: 30px;
  height: 30px;
  position: relative;
}

.inital-page .frame-34 {
  border: .71px solid;
  border-color: var(--text-300);
  background-color: #fff;
  border-radius: 14.89px;
  height: 30px;
}

.inital-page .group-21 {
  background-image: url("https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c4d04b4422ee4cb52442/img/vector-3.svg");
  background-size: 100% 100%;
  width: 17px;
  height: 17px;
  position: relative;
  top: 6px;
  left: 6px;
}

.inital-page .group-22 {
  background-color: #fff;
  border-radius: 2.18px;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 6px;
  left: 5px;
}

.inital-page .ellipse-11 {
  background-color: #000;
  border-radius: 1.59px;
  width: 3px;
  height: 3px;
  position: relative;
  top: 1px;
  left: 1px;
}

.inital-page .group-23 {
  background-color: #fff;
  border-radius: 2.18px;
  width: 4px;
  height: 4px;
  position: absolute;
  top: 2px;
  left: 10px;
}

.inital-page .frame-35 {
  flex: none;
  align-items: flex-start;
  gap: 87px;
  width: 90%;
  display: inline-flex;
  position: relative;
}

.inital-page .text-wrapper-29 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Aeonik TRIAL-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.inital-page .connect-wallet-to-wrapper {
  background-color: var(--primary-500main);
  cursor: pointer;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 344px;
  height: 44px;
  padding: 14px 62px;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

.inital-page .connect-wallet-to-wrapper.disabled {
  cursor: not-allowed;
  background-color: #ccc;
}

.inital-page .connect-wallet-to {
  color: var(--white);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.inital-page .button-instance {
  position: fixed !important;
  top: 66px !important;
  left: 267px !important;
}

.inital-page .frame-36 {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  display: inline-flex;
  position: fixed;
  top: 0;
  left: 0;
}

.inital-page .status-bar-2 {
  width: 375px !important;
}

.inital-page .logo-wrapper {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 54px;
  margin-right: 10px;
  display: flex;
  position: relative;
  top: 10px;
}

.inital-page .logo-3 {
  align-items: center;
  gap: 5.44px;
  display: inline-flex;
  position: relative;
  left: 15px;
}

.inital-page .group-24 {
  width: 33.98px;
  height: 33.98px;
  position: relative;
}

.inital-page .frame-37 {
  background-color: #fff;
  border-radius: 16.86px;
  width: 34px;
  height: 34px;
}

.inital-page .group-25 {
  background-image: url("https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c4d04b4422ee4cb52442/img/vector-2.svg");
  background-size: 100% 100%;
  width: 20px;
  height: 20px;
  position: relative;
  top: 7px;
  left: 7px;
}

.inital-page .group-26 {
  background-color: #fff;
  border-radius: 2.47px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 7px;
  left: 6px;
}

.inital-page .ellipse-12 {
  background-color: #000;
  border-radius: 1.81px;
  width: 4px;
  height: 4px;
  position: relative;
  top: 1px;
  left: 1px;
}

.inital-page .group-27 {
  background-color: #fff;
  border-radius: 2.47px;
  width: 5px;
  height: 5px;
  position: absolute;
  top: 2px;
  left: 12px;
}

.inital-page .group-28 {
  width: 72.59px;
  height: 22.34px;
  position: relative;
}

.inital-page .group-29 {
  width: 12px;
  height: 18px;
  position: absolute;
  top: 4px;
  left: 0;
}

.inital-page .group-30 {
  border: 2.49px solid #fff;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 13px;
}

.inital-page .ellipse-13 {
  background-color: #fff;
  border-radius: 2.35px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 3px;
  left: 2px;
}

.inital-page .group-31 {
  border: 2.49px solid #fff;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 26px;
}

.inital-page .ellipse-14 {
  background-color: #fff;
  border-radius: 2.35px;
  width: 5px;
  height: 5px;
  position: relative;
  top: 3px;
  left: 4px;
}

.inital-page .group-32 {
  width: 12px;
  height: 16px;
  position: absolute;
  top: 0;
  left: 39px;
}

.inital-page .overlap-group-4 {
  height: 16px;
  position: relative;
}

.inital-page .ellipse-15 {
  border: 2.49px solid #fff;
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 0;
}

.inital-page .rectangle-7 {
  background-color: #fff;
  border-radius: 0 0 1.24px 1.24px;
  width: 2px;
  height: 12px;
  position: absolute;
  top: 0;
  left: 0;
}

.inital-page .subtract-3 {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 52px;
}

.inital-page .frame-38 {
  align-items: flex-start;
  width: 7px;
  height: 12px;
  display: flex;
  position: absolute;
  top: 4px;
  left: 65px;
}

.inital-page .rectangle-8 {
  background-color: #fff;
  width: 2.47px;
  height: 11.99px;
  position: relative;
  transform: rotate(180deg);
}

.inital-page .ellipse-16 {
  background-color: #fff;
  border-radius: 2.35px;
  width: 4.7px;
  height: 4.7px;
  position: relative;
}

.inital-page .overlap-4 {
  width: 100%;
  height: 100%;
  display: flex;
}

.inital-page .element-4 {
  width: 1698px;
  height: 1084px;
  position: absolute;
  top: 0;
  left: 406px;
}

.inital-page .wallet-3 {
  position: absolute;
  top: 5%;
  right: 5%;
}

.inital-page .frame-39 {
  background-color: #02111c;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35.6%;
  height: 100%;
  display: flex;
}

.inital-page .frame-40 {
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3vh;
  width: 90%;
  max-width: 100%;
  height: auto;
  margin-left: 6vw;
  margin-right: 6vw;
  display: flex;
  position: relative;
  top: -5rem;
  overflow: visible;
}

.inital-page .frame-41, .inital-page .frame-44, .inital-page .frame-52 {
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.5vh;
  width: 100%;
  display: flex;
}

.inital-page .frame-41 {
  flex: 1 0 auto;
  gap: 2.8vh;
  position: relative;
}

.inital-page .frame-44 {
  flex: 1 0 auto;
  gap: 1.3vh;
  position: relative;
}

.inital-page .frame-52 {
  box-sizing: border-box;
  flex: 1 0 auto;
  gap: .93vh;
  width: 100%;
  position: relative;
}

.inital-page .logo-4 {
  flex: none;
  align-items: center;
  gap: 9.22px;
  display: inline-flex;
  position: relative;
}

.inital-page .group-33 {
  width: 57.64px;
  height: 57.64px;
  position: relative;
}

.inital-page .frame-42 {
  background-color: #fff;
  border-radius: 28.61px;
  width: 57px;
  height: 57px;
}

.inital-page .group-34 {
  background-image: url("https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c67f2404a95919d14329/img/vector-13.svg");
  background-size: 100% 100%;
  width: 33px;
  height: 34px;
  position: relative;
  top: 12px;
  left: 12px;
}

.inital-page .group-35 {
  background-color: #fff;
  border-radius: 4.18px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 12px;
  left: 10px;
}

.inital-page .ellipse-17 {
  background-color: #000;
  border-radius: 3.06px;
  width: 6px;
  height: 6px;
  position: relative;
  top: 1px;
  left: 1px;
}

.inital-page .group-36 {
  background-color: #fff;
  border-radius: 4.18px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 4px;
  left: 20px;
}

.inital-page .group-37 {
  width: 123.14px;
  height: 37.91px;
  position: relative;
}

.inital-page .group-38 {
  width: 20px;
  height: 31px;
  position: absolute;
  top: 7px;
  left: 0;
}

.inital-page .group-39 {
  border: 4.22px solid #fff;
  border-radius: 10.17px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 7px;
  left: 22px;
}

.inital-page .ellipse-18 {
  background-color: #fff;
  border-radius: 3.99px;
  width: 8px;
  height: 8px;
  position: relative;
  top: 6px;
  left: 4px;
}

.inital-page .group-40 {
  border: 4.22px solid #fff;
  border-radius: 10.17px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 7px;
  left: 44px;
}

.inital-page .ellipse-19 {
  background-color: #fff;
  border-radius: 3.99px;
  width: 8px;
  height: 8px;
  position: relative;
  top: 6px;
  left: 6px;
}

.inital-page .group-41 {
  width: 20px;
  height: 27px;
  position: absolute;
  top: 0;
  left: 67px;
}

.inital-page .overlap-group-5 {
  height: 27px;
  position: relative;
}

.inital-page .ellipse-20 {
  border: 4.22px solid #fff;
  border-radius: 10.17px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 7px;
  left: 0;
}

.inital-page .rectangle-9 {
  background-color: #fff;
  border-radius: 0 0 2.1px 2.1px;
  width: 4px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.inital-page .subtract-4 {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 7px;
  left: 89px;
}

.inital-page .frame-43 {
  align-items: flex-start;
  width: 12px;
  height: 20px;
  display: flex;
  position: absolute;
  top: 7px;
  left: 111px;
}

.inital-page .rectangle-10 {
  background-color: #fff;
  width: 4.19px;
  height: 20.34px;
  position: relative;
  transform: rotate(180deg);
}

.inital-page .ellipse-21 {
  background-color: #fff;
  border-radius: 3.99px;
  width: 7.97px;
  height: 7.97px;
  position: relative;
}

.inital-page .group-42 {
  width: 128px;
  height: 50px;
  position: relative;
}

.inital-page .frame-45 {
  background-color: #fff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 16px 14px;
  display: inline-flex;
  position: relative;
}

.inital-page .text-wrapper-30 {
  color: var(--primary-500main);
  font-family: var(--content-bold-font-family);
  font-size: 1vw;
  font-style: var(--content-bold-font-style);
  font-weight: var(--content-bold-font-weight);
  letter-spacing: var(--content-bold-letter-spacing);
  line-height: var(--content-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  position: relative;
}

.inital-page .frame-46 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 16px;
  width: auto;
  display: inline-flex;
  position: relative;
}

.inital-page .text-wrapper-31 {
  color: var(--text-600);
  letter-spacing: 0;
  white-space: nowrap;
  width: fit-content;
  font-family: Arial, sans-serif;
  font-size: 1.6vw;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.inital-page .frame-47 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.inital-page .text-wrapper-32 {
  color: var(--text-300);
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  margin-top: -1px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

@media (width >= 200px) {
  .inital-page .text-wrapper-32 {
    font-size: 30px;
  }
}

.inital-page .frame-48 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 12px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.inital-page .frame-49 {
  border: 1px solid;
  border-color: var(--primary-500main);
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 66px;
  position: relative;
}

.inital-page .frame-50 {
  align-items: center;
  gap: 10px;
  width: 90%;
  display: inline-flex;
  position: relative;
  top: 12px;
  left: 20px;
}

.inital-page .group-43 {
  width: 42px;
  height: 42px;
  position: relative;
}

.inital-page .frame-51 {
  border: 1px solid;
  border-color: var(--text-300);
  background-color: #fff;
  border-radius: 20.84px;
  height: 42px;
}

.inital-page .group-44 {
  background-image: url("https://cdn.animaapp.com/projects/6653c4b266313a614d4fa54c/releases/6653c67f2404a95919d14329/img/vector-14.svg");
  background-size: 100% 100%;
  width: 24px;
  height: 24px;
  position: relative;
  top: 8px;
}

.inital-page .group-45 {
  background-color: #fff;
  border-radius: 3.05px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 9px;
  left: 7px;
}

.inital-page .ellipse-22 {
  background-color: #000;
  border-radius: 2.23px;
  width: 4px;
  height: 4px;
  position: relative;
  top: 1px;
  left: 1px;
}

.inital-page .group-46 {
  background-color: #fff;
  border-radius: 3.05px;
  width: 6px;
  height: 6px;
  position: absolute;
  top: 3px;
  left: 15px;
}

.inital-page .enter-your-name-here {
  color: #000;
  letter-spacing: .32px;
  white-space: nowrap;
  box-sizing: border-box;
  caret-color: #ffba15;
  background: #fff;
  border: none;
  border-radius: 0;
  width: 95%;
  height: 40px;
  padding: 10px;
  font-family: Helvetica;
  font-size: 1vw;
  font-weight: 400;
  line-height: normal;
  display: block;
  position: relative;
}

.inital-page .enter-your-name-here:focus {
  border-bottom: 2px solid #ffba15;
  outline: none;
}

.inital-page .enter-your-name-here::placeholder {
  color: gray;
}

@media (width <= 786px) {
  .inital-page .enter-your-name-here {
    width: 94%;
    height: 35px;
    padding: 8px;
    font-size: 14px;
  }
}

.inital-page .frame-53 {
  background-color: var(--primary-500main);
  border: 1px solid var(--primary-500main);
  cursor: pointer;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 66px;
  transition: background-color .3s, transform .3s;
  display: flex;
  position: relative;
}

.inital-page .frame-53:hover {
  background-color: #f90;
}

.inital-page .frame-53 {
  background-color: var(--primary-500main);
  border: 1px solid var(--primary-500main);
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 66px;
  display: flex;
  position: relative;
}

.inital-page .frame-53.disabled {
  cursor: not-allowed;
  background-color: #ccc;
  border-color: #ccc;
}

@media (width <= 786px) {
  .inital-page .enter-your-name-here {
    width: 94%;
    height: 35px;
    padding: 8px;
    font-size: 14px;
  }
}

.inital-page .text-wrapper-33 {
  color: #fff;
  letter-spacing: .32px;
  white-space: nowrap;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 1.2vw;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.inital-page .iframe-container {
  z-index: 1000;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 103vh;
  display: flex;
}

.inital-page .iframe-container iframe {
  border: none;
  width: 100%;
  height: 100%;
}

.inital-page .frame-39, .inital-page .iframe-container {
  transition: background-color .3s;
}

.inital-page .connect-button {
  z-index: 1005;
  z-index: 1005;
  max-height: 55px;
  padding: 10px;
  position: fixed;
  top: 4%;
  right: 5%;
}

@media (width <= 786px) {
  .inital-page .connect-button {
    z-index: 1005;
    padding: 0%;
    position: relative;
    top: 0;
  }
}

.inital-page .frame-168 {
  flex-direction: column;
  align-items: center;
  gap: 13px;
  width: 100%;
  max-width: 344px;
  display: flex;
  position: relative;
}

.inital-page .frame-169 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.inital-page .to-fully-secure-your {
  color: var(--text-300);
  letter-spacing: .28px;
  width: 344px;
  margin-top: -1px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.inital-page .text-wrapper-93 {
  letter-spacing: .04px;
}

.inital-page .text-wrapper-94 {
  letter-spacing: .04px;
  font-family: Helvetica;
  font-weight: 700;
}

.inital-page .frame-170 {
  border: 1px solid;
  border-color: var(--primary-500main);
  background-color: #fff;
  border-radius: 10px;
  flex: none;
  align-items: center;
  gap: 6px;
  width: 100%;
  height: auto;
  padding: 9px 14px;
  display: flex;
  position: relative;
}

.inital-page .frame-171 {
  flex: none;
  align-items: flex-start;
  gap: 87px;
  width: 100%;
  margin-right: -3px;
  display: inline-flex;
  position: relative;
}

.inital-page .text-wrapper-95 {
  color: var(--text-900);
  letter-spacing: 0;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  min-height: 5.3rem;
  margin-top: -1px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.inital-page .text-wrapper-96 {
  color: var(--primary-500main);
  letter-spacing: .24px;
  white-space: nowrap;
  width: 100%;
  font-family: Aeonik TRIAL-Regular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.inital-page .user-name-wrapper {
  color: #5a68ff;
  font-family: Aeonik TRIAL-Regular, Helvetica;
  font-size: 20px;
  font-weight: 1000;
  font-style: bold;
  letter-spacing: .24px;
  white-space: nowrap;
  width: 100%;
  line-height: normal;
  position: relative;
}

.inital-page .web-user-name-wrapper {
  color: #5a68ff;
  font-family: Aeonik TRIAL-Regular, Helvetica;
  font-size: 40px;
  font-weight: 1000;
  font-style: bold;
  letter-spacing: .24px;
  white-space: nowrap;
  width: 100%;
  line-height: normal;
  position: relative;
}

.inital-page .wallet-10 {
  background-color: #ffba15;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 344px;
  height: 44px;
  padding: 14px 62px;
  display: flex;
  position: relative;
}

.inital-page .frame-172 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: -2px;
  margin-bottom: -2px;
  display: inline-flex;
  position: relative;
}

.inital-page .property-1-twitter {
  justify-content: center;
  align-items: center;
  display: flex;
  border: .4px solid !important;
  border-radius: 10px !important;
  width: 20px !important;
  height: 20px !important;
  position: relative !important;
}

.inital-page .twitter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.inital-page .x-2 {
  max-width: 100%;
  max-height: 100%;
}

.inital-page .text-wrapper-97 {
  color: var(--white);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.inital-page .frame-9, .inital-page .frame-168 {
  flex-direction: column;
  align-items: center;
  gap: 13px;
  width: 100%;
  max-width: 344px;
  display: flex;
  position: relative;
}

.inital-page .frame-10 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 6px;
  display: inline-flex;
  position: relative;
}

.inital-page .text-wrapper-15 {
  color: var(--text-300);
  letter-spacing: .28px;
  width: 344px;
  margin-top: -1px;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.inital-page .wallet-2 {
  background-color: #ffba15;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 344px;
  height: 44px;
  padding: 14px 62px;
  display: flex;
  position: relative;
}

.inital-page .frame-11 {
  flex: none;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: -2px;
  margin-bottom: -2px;
  display: inline-flex;
  position: relative;
}

.inital-page .discord-2 {
  width: 20px;
  height: 20px;
  position: relative;
}

.inital-page .text-wrapper-16 {
  color: var(--white);
  font-family: var(--notes-bold-font-family);
  font-size: var(--notes-bold-font-size);
  font-style: var(--notes-bold-font-style);
  font-weight: var(--notes-bold-font-weight);
  letter-spacing: var(--notes-bold-letter-spacing);
  line-height: var(--notes-bold-line-height);
  white-space: nowrap;
  width: fit-content;
  position: relative;
}

.inital-page .text-wrapper-80 {
  color: #d70000;
  white-space: nowrap;
  width: fit-content;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

@media (width <= 786px) {
  .inital-page .text-wrapper-80 {
    width: 100%;
    max-width: 344px;
    font-size: 12px;
  }
}

.inital-page .modifed-container {
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
}

.inital-page .frame-182 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  height: max-content;
  display: inline-flex;
  position: relative;
}

.inital-page .frame-185 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  display: inline-flex;
  position: relative;
}

.inital-page .frame-186 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.inital-page .to-fully-secure-your-2 {
  color: var(--text-300);
  letter-spacing: .32px;
  width: 100%;
  margin-top: -1px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

@media (width >= 2000px) {
  .inital-page .to-fully-secure-your-2, .inital-page .ive-just-claimed-my, .inital-page .text-wrapper-105, .inital-page .wallet-address-wrapper, .inital-page .text-wrapper-22, .inital-page .text-wrapper-23 {
    font-size: 25px !important;
  }
}

.inital-page .text-wrapper-103 {
  letter-spacing: .05px;
}

.inital-page .text-wrapper-104 {
  letter-spacing: .05px;
  font-family: Helvetica;
  font-weight: 700;
}

.inital-page .frame-187 {
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  width: 98%;
  height: 82px;
  display: inline-flex;
  position: relative;
}

.inital-page .frame-188 {
  border: 1px solid;
  border-color: var(--primary-500main);
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 82px;
  position: relative;
}

.inital-page .ive-just-claimed-my-wrapper {
  flex: none;
  align-items: flex-start;
  width: 100%;
  height: 98%;
  margin-right: -3px;
  display: inline-flex;
  position: relative;
}

.inital-page .ive-just-claimed-my {
  color: var(--text-900);
  letter-spacing: .2px;
  position: flex;
  background: none;
  border: none;
  outline: none;
  flex: none;
  align-items: flex-start;
  gap: 187px;
  width: 100%;
  height: 100%;
  min-height: 4.5rem;
  margin-top: -1px;
  padding: 2%;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
}

.inital-page .text-wrapper-79 {
  color: #ffba15;
  letter-spacing: .32px;
  white-space: nowrap;
  width: 100%;
  font-family: Aeonik TRIAL-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

@media (width >= 2000px) {
  .inital-page .text-wrapper-79 {
    font-size: 25px;
  }
}

.inital-page .text-wrapper-105 {
  color: #ffba15;
  letter-spacing: .32px;
  white-space: nowrap;
  width: 100%;
  font-family: Aeonik TRIAL-Regular, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  position: relative;
}

.inital-page .frame-189 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: flex;
  position: relative;
}

.inital-page .frame-190 {
  border: 1px solid;
  border-color: var(--text-900);
  cursor: pointer;
  background-color: #ffba15;
  border-radius: 10px;
  width: 100%;
  height: 66px;
  transition: background-color .3s, transform .3s;
  position: relative;
}

.inital-page .frame-190 :hover {
  background-color: #f90;
}

.inital-page .frame-191 {
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 100%;
  display: flex;
  position: relative;
}

.inital-page .icons-2 {
  border: .8px solid !important;
  border-radius: 20px !important;
  width: 40px !important;
  height: 40px !important;
  position: relative !important;
}

.inital-page .icons-3 {
  border-radius: 333.33px !important;
  width: 27px !important;
  height: 27px !important;
  top: 6px !important;
  left: 6px !important;
}

.inital-page .x-3 {
  width: 16px !important;
  height: 16px !important;
  position: absolute !important;
  top: 5px !important;
  left: 5px !important;
}

.inital-page .text-wrapper-106 {
  color: #fff;
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.inital-page .frame-23 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.inital-page .frame-24 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.inital-page .text-wrapper-22 {
  color: var(--text-300);
  letter-spacing: .32px;
  width: 100%;
  margin-top: -1px;
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

.inital-page .frame-20 {
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  display: inline-flex;
  position: relative;
}

.inital-page .frame-25 {
  cursor: pointer;
  flex-direction: column;
  flex: none;
  align-items: flex-start;
  gap: 10px;
  width: 98%;
  display: flex;
  position: relative;
}

.inital-page .frame-26 {
  border: 1px solid;
  border-color: var(--text-900);
  background-color: #ffba15;
  border-radius: 10px;
  width: 100%;
  height: 66px;
  transition: background-color .3s, transform .3s;
  position: relative;
}

.inital-page .frame-26:hover {
  background-color: #f90;
}

.inital-page .frame-27 {
  position: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 100%;
  display: inline-flex;
}

.inital-page .discord-3 {
  width: 40px;
  height: 40px;
  position: relative;
}

.inital-page .text-wrapper-23 {
  color: #fff;
  letter-spacing: .32px;
  white-space: nowrap;
  width: fit-content;
  font-family: Aeonik TRIAL-Bold, Helvetica;
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.custom-wallet-button-container {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 66px;
  display: flex;
}

@media (width <= 786px) {
  .inital-page .enter-your-name-here {
    width: 94%;
    height: 35px;
    padding: 8px;
    font-size: 16px;
  }

  .inital-page .custom-wallet-button-container {
    width: 100%;
    max-width: 344px;
  }
}

.wallet-adapter-dropdown {
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

.wallet-adapter-button {
  color: #fff;
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 2vh;
  height: 100%;
  font-size: 16px;
  display: flex;
}

.wallet-adapter-button {
  color: #fff;
  cursor: pointer;
  background-color: #0000;
  border: none;
  border-radius: 4px;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 48px;
  display: flex;
}

.wallet-adapter-button-trigger {
  background-color: #512da8;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: #fff;
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-button[disabled] {
  color: #999;
  cursor: not-allowed;
  background: #404144;
}

.wallet-adapter-button-end-icon, .wallet-adapter-button-start-icon, .wallet-adapter-button-end-icon img, .wallet-adapter-button-start-icon img {
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  display: flex;
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  display: inline-block;
  position: relative;
}

.wallet-adapter-dropdown-list {
  z-index: 99;
  grid-row-gap: 10px;
  opacity: 0;
  visibility: hidden;
  background: #2c2d30;
  border-radius: 10px;
  grid-template-rows: 1fr;
  margin: 0;
  padding: 10px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  list-style: none;
  transition: opacity .2s, transform .2s, visibility .2s;
  display: grid;
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  color: #fff;
  border: none;
  border-radius: 6px;
  outline: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 37px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 600;
  display: flex;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
  fill: #999;
  align-self: center;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active svg {
  transition: transform .15s ease-in;
  transform: rotate(180deg);
}

.wallet-adapter-modal {
  opacity: 0;
  z-index: 1040;
  background: #00000080;
  transition: opacity .15s linear;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  cursor: pointer;
  background: #1a1f2e;
  border: none;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  display: flex;
  position: absolute;
  top: 18px;
  right: 18px;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: #fff;
}

.wallet-adapter-modal-button-close svg {
  fill: #777;
  transition: fill .2s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: #00000080;
  position: fixed;
  inset: 0;
}

.wallet-adapter-modal-container {
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 6rem);
  margin: 3rem;
  display: flex;
}

@media (width <= 480px) {
  .wallet-adapter-modal-container {
    min-height: calc(100vh - 2rem);
    margin: 1rem;
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  z-index: 1050;
  background: #10141f;
  border-radius: 10px;
  flex-direction: column;
  flex: 1;
  align-items: center;
  max-width: 400px;
  font-family: DM Sans, Roboto, Helvetica Neue, Helvetica, Arial, sans-serif;
  display: flex;
  position: relative;
  box-shadow: 0 8px 20px #0009;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  text-align: center;
  color: #fff;
  margin: 0;
  padding: 64px 48px 48px;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

@media (width <= 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  width: 100%;
  margin: 0 0 12px;
  padding: 0;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  border-radius: 0;
  font-size: 18px;
  font-weight: 400;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon, .wallet-adapter-modal-list .wallet-adapter-button-start-icon, .wallet-adapter-modal-list .wallet-adapter-button-end-icon img, .wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  opacity: .6;
  margin-left: auto;
  font-size: 14px;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  color: #fff;
  background-color: #0000;
  border: none;
  align-self: flex-end;
  align-items: center;
  padding: 12px 24px 24px 12px;
  display: flex;
}

.wallet-adapter-modal-list-more svg {
  fill: #fff;
  margin-left: .5rem;
  transition: all .1s;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 24px 24px;
  display: flex;
}

.wallet-adapter-modal-middle-button {
  cursor: pointer;
  color: #fff;
  background-color: #512da8;
  border: none;
  border-radius: 8px;
  width: 100%;
  margin-top: 48px;
  padding: 12px;
  font-size: 18px;
  display: block;
}
/*# sourceMappingURL=index.ef7defe1.css.map */
